body {
  margin-top: 125px;
}

.custom-navbar {
  background-color: #0fc9f2;
  margin: 0;
}

.main-heading {
  text-align: left;
}

#contact h2 {
  /* Targets the h2 within the element with id="contact" */
  text-align: center; /* Changed to center */
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-top: 0;
}


